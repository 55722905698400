<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <v-card>
                <v-toolbar dense flat color="#3F51B5" dark>
                    <v-toolbar-title v-if="!isActivatingLoginShield">Sign in</v-toolbar-title> <!-- TODO: add isViewReady && ? -->
                    <v-toolbar-title v-if="isActivatingLoginShield">Activate LoginShield</v-toolbar-title> <!-- TODO: add isViewReady && ? -->
                    <v-spacer></v-spacer>
                </v-toolbar>
                <template v-if="!isViewReady">
                    <v-card-text>
                        <p class="mb-0">Please wait...</p>
                    </v-card-text>
                </template>
                <template v-if="start && !redirect && !isSignupRequired">
                    <!-- TODO: if we're going to allow sign in with any other service besides LoginShield, such as sign in with Google, sign in with Microsoft, etc. then we would show the list here, since user returns from those identity providers with verified email -->
                    <v-card-text>
                        <p>What is your email address?</p>
                    </v-card-text>
                    <v-form @submit.prevent="signin" onSubmit="return false;" @keyup.enter.native.prevent="signin" class="mx-4 pb-6">
                        <v-text-field v-model="email" ref="emailInput" dense solo color="#3F51B5" hint="The email address you used to sign up" placeholder="Email" :error-messages="emailError">
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                            </template>
                        </v-text-field>
                        <v-row no-gutters justify="center">
                        <v-btn @click="signin" elevation="4" color="#3F51B5" class="white--text mt-4">Continue</v-btn>
                        </v-row>
                    </v-form>
                </template>
                <template v-if="start && redirect">
                    <v-card-text>
                        <p><a :href="redirect">Continue to sign in</a></p>
                    </v-card-text>
                </template>
                <template v-if="start && status === 'verify_email' && redirect">
                    <v-card-text>
                        <p><a :href="redirect">Continue to email verification</a></p>
                    </v-card-text>
                </template>
                <template v-if="start && status === 'signup_required'">
                    <v-text-field v-model="email" dense solo flat color="#3F51B5" placeholder="Email" readonly class="mt-2">
                        <template #prepend-inner>
                            <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" -->
                        </template>
                    </v-text-field>
                    <v-card-text>
                        <!-- NOTE: since the user verified the email address, we can inform them of the status -->
                        <p>
                            Looks like you haven't signed up yet, or the sign up process was interrupted.
                        </p>
                        <p><a @click.prevent="signup" href="#">Continue to sign up</a></p>
                        <p><a @click.prevent="switchEnterprise" href="#">Use a different email address</a></p>
                    </v-card-text>
                </template>
                <!-- TODO: for LoginShield iframe... -->
                <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row> -->
            </v-card>
            <template v-if="start && !redirect">
                <p class="mt-6 grey--text text--darken-2 text-center">Don't have an enterprise yet? <router-link :to="{ name: 'signup' }">Sign up</router-link></p>
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError || loginshieldStartError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isEmpty, isValidEmail } from '@/sdk/input';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    data: () => ({
        email: null,
        emailError: null,
        isViewReady: false,
        status: null, // could be 'verify_email', 'signup_required', or 'loginshield'
        redirect: null, // only when server responds with a redirect url
        signupRequired: false,
        serverError: null,
        requestError: null,
        forbiddenError: null,
        submitTimeout: null,
        // errorTimeout: null,
        // all the attributes below are old and need to be reconsidered
        from: null,
        isActivatingLoginShield: false,
        loginEmailInput: true,
        loginPasswordInput: false,
        loginWithLoginShield: false,
        password: null,
        passwordError: false,
        loginshieldStartError: false,
        isRememberMeChecked: null,
    }),

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
            enterprise: (state) => state.enterprise,
            focus: (state) => state.focus,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        start() {
            return isEmpty(this.$route.query.loginshield_token) && isEmpty(this.$route.query.loginfront); // TODO: remove loginfront, keep loginshield
        },
        isSignupRequired() {
            return this.status === 'signup_required';
        },
    },

    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        isRememberMeChecked(value) {
            console.log(`Login.vue: isRememberMeChecked watcher: storing ${value}`);
            localStorage.setItem('rememberMe', value);
        },
        focus() {
            if (this.start) {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('emailInput'); }, 1);
                });
            }
        },
    },

    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async init() {
            const token = this.$route.query.loginshield_token ?? this.$route.query.loginfront;
            if (token) {
                await this.checkLoginToken(token);
            } else {
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('emailInput'); }, 1);
                });
            }
            this.isViewReady = true;
        },
        async checkLoginToken(token) {
            try {
                this.$store.commit('loading', { checkLoginToken: true });
                // TODO: when loginfront is merged to loginshield, this query parameter will be renamed to loginshield_token; so we can remove the references to 'loginfront'
                if (token) {
                    const { isAuthenticated } = await this.$client.main().authn.checkLogin({ token });
                    if (isAuthenticated) {
                        await this.$store.dispatch('refresh');
                        this.redirectAfterLogin();
                        return;
                    }
                    this.$store.commit('setSession', { isAuthenticated: false });
                    this.$store.commit('setUser', {});
                }
            } catch (err) {
                console.error('start login failed', err);
                this.loginshieldStartError = true;
            } finally {
                this.$store.commit('loading', { checkLoginToken: false });
            }
        },
        resetErrors() {
            this.passwordError = false;
            this.loginshieldStartError = false;
        },
        resetLoginForm() {
            this.loginWithLoginShield = false;
            this.loginPasswordInput = false;
            this.loginEmailInput = true;
            this.email = '';
            this.password = '';
            this.$refs.emailField.reset();
            this.$refs.passwordField.reset();
            this.$nextTick(() => this.$refs.emailField.focus());
        },
        async signin() {
            const now = Date.now();
            if (Number.isInteger(this.submitTimeout) && now - this.submitTimeout < 500) {
                return;
            }
            this.submitTimeout = now;
            if (!isValidEmail(this.email)) {
                this.emailError = 'Must be a valid email address';
                return;
            }
            /*
            this.loginEmailInput = false;
            this.loginWithLoginShield = true;
            this.startLoginShield({
                username: this.email,
                interactionId: this.interactionId,
                nextRoute: this.next,
                rememberMe: true,
            });
            */
            try {
                this.resetErrors();
                this.$store.commit('loading', { signin: true });
                const { status, redirect } = await this.$client.main().authn.startLogin({ email: this.email });
                console.log(`Login.vue init redirect: ${redirect}`);
                if (['verify_email', 'loginfront'].includes(status) && redirect) {
                    // show redirect link while we try automatic redirect, with a brief delay to avoid flicker
                    setTimeout(() => {
                        this.status = status;
                        this.redirect = redirect;
                    }, 1000);
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        // TODO: also show link for user to click
                        window.location.href = redirect;
                    }
                } else if (status === 'signup_required') {
                    // this happens when email is verified but user record doesn't exist; redirect
                    // to the signup process
                    this.status = status;
                } else if (status === 'setup_required') {
                    // user exists, email is verified, but user hasn't set up authentication yet
                    await this.$store.dispatch('refresh');
                    this.redirectAfterLogin();
                    return;
                }
                // TODO: below is older code that has password (which we removed from this app) and loginshield (which we still need to add)
                /*
                const { mechanism } = await this.$client.main().authn.login({
                    username: this.email,
                });
                if (mechanism === 'password') {
                    this.loginEmailInput = false;
                    this.loginPasswordInput = true;
                    this.$nextTick(() => this.$refs.passwordField.focus());
                } else if (mechanism === 'loginshield') {
                    this.loginEmailInput = false;
                    this.loginWithLoginShield = true;
                    this.startLoginShield({
                        username: this.email,
                        interactionId: this.interactionId,
                        nextRoute: this.next,
                        rememberMe: true, // TODO: should this be this.isRememberMeChecked ?
                    });
                } else {
                    this.passwordError = true;
                    this.resetLoginForm();
                }
                */
            } finally {
                this.$store.commit('loading', { signin: false });
            }
        },
        async redirectAfterLogin({ nextInteractionId } = {}) {
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$router.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$router.push({ name: 'user-dashboard', query: { i: nextInteractionId } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$router.push(this.next);
                return;
            }
            this.$router.push({ name: 'user-dashboard' });
        },
        signup() {
            this.$router.replace({
                name: 'signup',
                query: {
                    ...this.$route.query,
                    step: 'verify_email',
                    email: this.email,
                    t: Date.now(),
                },
            });
        },
        switchEnterprise() {
            this.email = null;
            this.status = null;
            this.$nextTick(() => {
                setTimeout(() => { this.activate('emailInput'); }, 1);
            });
        },
    },

    mounted() {
        const rememberMeStored = localStorage.getItem('rememberMe');
        this.isRememberMeChecked = rememberMeStored === 'true';

        // if another view redirected here as part of a larger interaction like creating
        // an enterprise, there should be an interaction id in the query
        this.interactionId = this.$route.query.i;

        // if another view redirected here and already knows the email, we can pre-fill
        // the email in the login form; the user can still change it
        this.email = this.$route.query.email;

        // if another view redirected here with the intent of returning, it has to provide
        // the path and optional query parameters to redirect the user after login; the path
        // MUST be a relative path because this is not the right place for unrelated logic
        // about where we can redirect the user; the 'next' route could then redirect the
        // user to an offsite URL if needed
        const { next } = this.$route.query;
        if (typeof next === 'string' && next.startsWith('/')) {
            this.next = next;
        }
        console.log('Login.vue: mounted with isReady: %o and isAuthenticated: %o', this.isReady, this.isAuthenticated);
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
